
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');




.App {text-align: center;
  font-family: 'Red Hat Display', sans-serif;


}

.App-header {
  background-color: #fcf2d5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-body {background-color: #fff9c4;min-height: 100vh;display: flex;
  flex-direction: column;align-items: center;font-size: calc(10px + 2vmin);color: rgb(0, 0, 0);
 

}






/* nav bar style start*/
.navbar {
  display: flex;
  background-color: #0A9396;
  width: 90%;
  justify-content: flex-end;
  margin-top: 20px;

}




/*hero container start
  -remove border */
.heroContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* border: 1px solid black;
} */

/* .heroInfo {
  /* border: 1px solid black; */
  
}
.heroImageContainer {}
  /* border: 1px solid black; */
/* } */ 

.heroImage {
  border-radius: 70px;
}
/*hero container end */


